textarea:focus,
input:focus {
  outline: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  max-width: 480px;
  margin: auto;
  margin-bottom: 10px;
  box-shadow: 0px 10px 28px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

[role='tablist'] {
  padding-bottom: 2px;
}

[role='tablist'] a {
  padding-bottom: 3px;
  flex-direction: column;
}

[role='tablist'] a div {
  flex: 1;
}

[role='tablist'] a span {
  font-size: 10px;
  margin: 0;
}

[role='dialog'],
div[class^='r-bottom'],
div[class*=' r-bottom'] {
  max-width: 480px;
  width: 100%;
  margin: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::placeholder {
  color: #9a9a9a !important;
}

:-moz-placeholder {
  color: #9a9a9a !important;
}

::-ms-input-placeholder {
  color: #9a9a9a !important;
}

::-webkit-input-placeholder {
  color: #9a9a9a !important;
}
