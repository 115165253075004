@import '~react-datepicker/dist/react-datepicker.css';

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover,
.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  background-color: #6ac982;
}

.react-datepicker__header--time {
  display: none;
}
